<template>
	<div class="Devis">
		<v-container class="mb-10">
			<v-card class="mt-5">
				<v-row class="pa-5">
					<v-col v-bind:class="{
						'col-10': $firebase.auth().currentUser && !$vuetify.breakpoint.smAndDown,
						'col-9': !$firebase.auth().currentUser && !$vuetify.breakpoint.smAndDown,
						'col-6': $vuetify.breakpoint.smAndDown
					}">
						<h1 class="text-uppercase display-2 mb-5">Devis</h1>

						<v-chip outlined :color="$cssGlobal.getStatus($listUtils.quoteStatus[quote.status], 'quote')">{{ $listUtils.quoteStatus[quote.status] }}</v-chip>
					</v-col>
					<v-col class="text-right" v-bind:class="{
						'col-2': $firebase.auth().currentUser,
						'col-3': !$firebase.auth().currentUser,
						'col-6': $vuetify.breakpoint.smAndDown
					}">
						<p class="display-1 mb-0" v-if="$firebase.auth().currentUser">Devis n°{{ quote.nQuote }}</p>
						
						<p class="overline mt-0" v-if="$firebase.auth().currentUser">Ref: {{ quote.reference }}</p>
						<h3 v-else>Référence : {{ quote.reference }}</h3>

						<v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px" v-if="quote.status == 0 && $firebase.auth().currentUser">

							<template v-slot:activator="{ on, attrs }">
								<v-text-field v-if="quote.status == 0 && $firebase.auth().currentUser" v-model="formattedDate" label="Date" readonly v-bind="attrs" v-on="on"></v-text-field>
								<v-text-field v-else disabled v-model="formattedDate" label="Date" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>

							<v-date-picker first-day-of-week="1" ref="picker" v-model="dDate" min="1950-01-01" @change="save"></v-date-picker>
						</v-menu>
						<p v-else class="font-weight-light">En date du {{ formattedDate }}</p>
					</v-col>

					<v-col cols="6">
						<p class="headline font-weight-bold text-uppercase">Émetteur</p>

						<v-col v-bind:class="{
							'col-12': $vuetify.breakpoint.smAndDown,
							'col-auto': !$vuetify.breakpoint.smAndDown
						}" class="float-left" v-if="owner.avatar">

							<v-img width="7vw" :src="$functions.getImgLink(owner.avatar)" v-if="$vuetify.breakpoint.smAndUp"></v-img>
							<v-img :src="$functions.getImgLink(owner.avatar, 50)" v-else></v-img>

						</v-col>

						<v-col cols="auto" class="float-left">
							<p class="title mb-0">{{ owner.name }}</p>
							<p class="subtitle">{{ owner.email }}</p>

							<p class="mt-2 mb-0">{{ owner.address.street }}</p>
							<template v-if="owner.address.city && owner.address.region && owner.address.country">
								<p>{{ owner.address.postalCode }} {{ owner.address.city }}・{{ owner.address.region }}・{{ owner.address.country }}</p>
							</template>

							<a v-if="owner.website && $vuetify.breakpoint.smAndUp" :href="owner.website" class="text-decoration-none" target="_blank">{{ owner.website }}</a>
						</v-col>
					</v-col>

					<v-col cols="6">
						<p class="headline font-weight-bold text-uppercase">Destinataire</p>

						<v-col v-bind:class="{
							'col-12': $vuetify.breakpoint.smAndDown,
							'col-auto': !$vuetify.breakpoint.smAndDown
						}" class="float-left" v-if="customer.avatar">

							<v-img width="7vw" :src="$functions.getImgLink(customer.avatar)" v-if="$vuetify.breakpoint.smAndUp"></v-img>
							<v-img :src="$functions.getImgLink(customer.avatar, 50)" v-else></v-img>

						</v-col>

						<v-col cols="auto" class="float-left">
							<p class="title mb-0" v-if="!pro">{{ customer.lastName.toUpperCase() }} {{ customer.firstName }}</p>
							<p class="title mb-0" v-else>{{ customer.name }}</p>
							<p class="subtitle">{{ customer.email }}</p>

							<p class="mt-2 mb-0">{{ customer.address.street }}</p>
							<template v-if="customer.address.city && customer.address.region && customer.address.country">
								<p>{{ customer.address.postalCode }} {{ customer.address.city }}・{{ customer.address.region }}・{{ customer.address.country }}</p>
							</template>

							<a v-if="customer.website && $vuetify.breakpoint.smAndUp" :href="customer.website" class="text-decoration-none" target="_blank">{{ customer.website }}</a>
						</v-col>
					</v-col>
				</v-row>

				<v-data-table hide-default-footer :headers="quoteElHeaders" :items="productsQuotes">
					<template v-slot:top>
						<v-toolbar>

							<!-- Dialog Begin -->
							<v-dialog v-model="dialogs.addProduct" fullscreen hide-overlay transition="dialog-bottom-transition">

								<template v-slot:activator="{ on, attrs }">
									<v-card-title>
										<v-spacer></v-spacer>
										<!-- Justify to Right -->
										<v-btn color="primary" class="my-2" right outlined v-bind="attrs" v-on="on" v-if="quote.status == 0 && $firebase.auth().currentUser">
											Ajouter un produit</v-btn>
									</v-card-title>
								</template>

								<v-card>
									<v-card-title>
										<span class="headline">Ajouter un produit <span v-if="activeTemp">temporaire</span></span>
									</v-card-title>

									<v-card-title>

										<v-btn class="ma-2 mt-4" outlined color="primary" v-if="activeTemp" @click="activeTemp = false">
											Liste des produits
										</v-btn>

										<v-btn class="ma-2 mt-4" outlined color="primary" v-if="!activeTemp" @click="activeTemp = true">
											Produit Temporaire
										</v-btn>
									</v-card-title>

									<v-card v-if="activeTemp">

										<v-card-text>
											<v-container>
												<v-row>
													<v-col cols="12" sm="8" md="8">
														<v-text-field label="Nom du produit" v-model="temporaryProduct.name"></v-text-field>
													</v-col>
													<v-col cols="12" sm="4" md="4">
														<v-text-field label="Référence" v-model="temporaryProduct.reference"></v-text-field>
													</v-col>
													<v-col cols="12" sm="6" md="6">
														<v-text-field type="number" label="Prix" hint="Prix Hors Taxe" v-model="temporaryProduct.value"></v-text-field>
													</v-col>
													<v-col cols="12" sm="6" md="6">
														<v-text-field type="number" label="Quantité" v-model="temporaryProduct.quantity"></v-text-field>
													</v-col>

													<v-col cols="12">
														<span class="mt-5 mb-1">Description courte</span>
														<vue-editor v-model="temporaryProduct.shortDesc" />
													</v-col>
													<v-col cols="12">
														<v-text-field label="Aperçu" hint="Lien vers l'aperçu produit" v-model="temporaryProduct.pictures[0].link"></v-text-field>
													</v-col>
												</v-row>
											</v-container>
										</v-card-text>

									</v-card>

									<v-card v-if="!activeTemp">

										<v-container>
											<v-row v-for="product in availableProducts" :key="product.id">
												<v-col cols="4">
													<v-img :lazy-src="$functions.getImgLink(product.pictures[0].link, 50)" max-height="180" contain :src="$functions.getImgLink(product.pictures[0].link, 200)"></v-img>
												</v-col>
												<v-col cols="8">
													<v-row>
														<v-col cols="8">
															<h3>{{product.name}}</h3>
															<p class="font-weight-light" v-if="product.reference">Référence : {{ product.reference }}</p>
														</v-col>
														<v-col cols="4">
															<v-btn class="ma-2" color="primary" outlined @click="addProductToQuote(product)">Ajouter au devis</v-btn>
														</v-col>

														<v-col cols="12">
															<p v-html="product.shortDesc"></p>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
										</v-container>

									</v-card>

									<v-card-actions class="mr-4">
										<v-spacer></v-spacer>
										<v-btn class="ma-2 mt-4" color="warning" outlined @click="dialogs.addProduct = false">Fermer</v-btn>
										<v-btn class="ma-2 mt-4" color="success" outlined v-if="activeTemp" @click="addProductToQuote(temporaryProduct, true), dialogs.addProduct = false">Ajouter</v-btn>
									</v-card-actions>
								</v-card>

								<v-btn id="lateral" fab small outlined color="primary" @click="dialogs.addProduct = false">
									<v-icon>mdi-chevron-down</v-icon>
								</v-btn>

							</v-dialog>
							<!-- Dialog End -->

						</v-toolbar>
					</template>

					<template v-slot:[`item.pictures`]="{ item }">
						<v-img :src="$functions.getImgLink(item.pictures[0].link, 200)" contain :lazy-src="$functions.getImgLink(item.pictures[0].link, 50)" aspect-ratio="1" max-width="200" max-height="200">
							<template v-slot:placeholder>
								<v-row class="fill-height ma-0" align="center" justify="center">
									<v-progress-circular indeterminate :size="50" :width="1" color="primary"></v-progress-circular>
								</v-row>
							</template>
						</v-img>
					</template>

					<template v-slot:[`item.quantity`]="{ item }">
						<h3 class="text-right">{{item.quantity}}x</h3>
					</template>

					<template v-slot:[`item.product`]="{ item }">
						<p class="title mb-1 mt-2">{{item.name}}</p>
						<span class="mb-2" v-html="item.shortDesc"></span>
					</template>

					<template v-slot:[`item.value`]="{ item }">
						<p class="title text-right">{{ Number(item.value).toLocaleString(undefined, { minimumFractionDigits: 2}) }} €</p>
					</template>

					<template v-slot:[`item.total`]="{ item }">
						<p class="title text-right">{{ Number(Number(item.quantity) * Number(item.value)).toLocaleString(undefined, { minimumFractionDigits: 2}) }} €</p>
					</template>

					<template v-slot:[`item.actions`]="{ item }" v-if="quote.status == 0 && $firebase.auth().currentUser">
						<v-icon small class="mr-2" @click="dialogs.modifyProduct = true, activeProduct = item">
							mdi-pencil
						</v-icon>
						<v-icon small @click="removeProductToQuote(item)">
							mdi-delete
						</v-icon>
					</template>
				</v-data-table>

				<v-divider></v-divider>

				<v-list-item class="mx-3" v-if="Number((sumField() - quote.discount.value) + (sumField() * (quote.wage/100))) !== Number(sumField())">
					<v-list-item-content>
						<v-list-item-title class="headline">Sous-Total</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action class="title">
						{{ Number(sumField()).toLocaleString(undefined, { minimumFractionDigits: 2}) }} €
					</v-list-item-action>
				</v-list-item>

				<v-divider v-if="Number((sumField() - quote.discount.value) + (sumField() * (quote.wage/100))) !== Number(sumField())"></v-divider>

				<v-list-item class="mx-3" two-line v-if="quote.status == 0 || quote.discount.value">
					<v-list-item-content>
						<v-list-item-title class="headline">Réduction</v-list-item-title>

						<v-list-item-subtitle>
							<v-text-field class="title text-left float-left" v-if="quote.status == 0 && $firebase.auth().currentUser" text-left v-model="quote.discount.label" label="Intitulé de la réduction"></v-text-field>
							<span class="title" v-else>{{ quote.discount.label }}</span>
						</v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action>
						<v-text-field class="title text-right float-right" v-if="quote.status == 0 && $firebase.auth().currentUser" reverse prefix="€" text-right v-model="quote.discount.value" label="Montant" min="0" max="100" type="number"></v-text-field>
						<span class="title" v-else>-{{ quote.discount.value }}€</span>
					</v-list-item-action>
				</v-list-item>

				<v-divider v-if="quote.status == 0 || quote.discount.value"></v-divider>

				<v-list-item class="mx-3">
					<v-list-item-content>
						<v-list-item-title class="headline">Taxe</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action>
						<v-text-field class="title text-right float-right" v-if="quote.status == 0 && $firebase.auth().currentUser" reverse prefix="%" text-right v-model="quote.wage" label="Taxe" min="0" max="100" type="number"></v-text-field>
						<span class="title" v-else>{{ quote.wage }}%</span>
					</v-list-item-action>
				</v-list-item>

				<v-divider></v-divider>

				<v-list-item class="mx-3">
					<v-list-item-content>
						<v-list-item-title class="headline">Total</v-list-item-title>
					</v-list-item-content>

					<v-list-item-action class="title">
						{{ Number((sumField() - quote.discount.value) + (sumField() * (quote.wage/100))).toLocaleString(undefined, { minimumFractionDigits: 2}) }} €
					</v-list-item-action>
				</v-list-item>

				<v-divider></v-divider>

				<v-form class="form mx-4">
					<v-col cols="12">
						<v-textarea v-if="quote.status == 0 && $firebase.auth().currentUser" v-model="quote.remark" name="Remarque" label="Remarque" hint="Remarque sur le devis, propositions et/ou précisions"></v-textarea>
						<div v-else-if="quote.remark">
							<p class="headline">Remarque</p>
							<p>{{ quote.remark }}</p>
						</div>

						<v-card-title v-if="$firebase.auth().currentUser">
							<v-btn color="success" v-if="quote.status == 1" left outlined @click="statusQuote(2)" class="mr-5 my-2">Accepter le devis</v-btn>
							<v-btn color="error" v-if="quote.status == 1" left outlined @click="statusQuote(3)" class="mr-5 my-2">Refuser le devis</v-btn>
							
							<v-spacer></v-spacer>
							
							<!--<v-btn color="primary" left outlined @click="submit" class="mr-2" disabled>Envoyer le devis</v-btn>-->

							<v-btn color="success" v-if="quote.status == 0" left outlined @click="statusQuote(1)" class="mr-5 my-2">Valider le devis</v-btn>
							<v-btn color="primary" v-else-if="quote.status == 1" left outlined @click="statusQuote(0)" class="mr-5 my-2">Passer le devis en brouillon</v-btn>

							<v-btn color="primary" v-if="quote.status == 0" right outlined @click="submit()" class="my-2">Sauvegarder</v-btn>
						</v-card-title>
					</v-col>
				</v-form>
			</v-card>
		</v-container>

		<v-dialog v-model="dialogs.modifyProduct" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Modifier le produit {{ activeProduct.name }}</span>
				</v-card-title>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col cols="12" sm="8" md="8">
								<v-text-field label="Nom du produit" v-model="activeProduct.name"></v-text-field>
							</v-col>
							<v-col cols="12" sm="4" md="4">
								<v-text-field label="Référence" v-model="activeProduct.reference"></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<v-text-field type="number" label="Prix" hint="Prix Hors Taxe" v-model="activeProduct.value"></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" md="6">
								<v-text-field type="number" label="Quantité" v-model="activeProduct.quantity"></v-text-field>
							</v-col>
							
							<v-col cols="12">
								<span class="mt-5 mb-1">Description courte</span>
								<vue-editor v-model="activeProduct.shortDesc" />
							</v-col>
							<v-col cols="12">
								<v-text-field label="Aperçu" hint="Lien vers l'aperçu produit" v-model="activeProduct.pictures[0].link"></v-text-field>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="dialogs.modifyProduct = false">
						Fermer
					</v-btn>
					<v-btn color="success darken-1" text @click="modifyProduct(), dialogs.modifyProduct = false">
						Sauvegarder
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<style>
	#blurMe {
		filter: blur(10px);
		mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 1)), to(rgba(0, 0, 0, 0)));
	}


	#lateral {
		position: fixed;
		top: 15px;
		right: 15px;
		margin: 0 0 16px 16px;
	}

	#create .v-speed-dial {
		position: absolute;
	}

	#create .v-btn--floating {
		position: relative;
	}
</style>


<script>
	import { VueEditor } from "vue2-editor";
	export default {
		name: 'FicheDevis',
		props: {
			'id': {
				type: String
			}
		},
		components: {
			VueEditor
		},
		data() {
			return {
				quote: this.$models.quote,
				owner: this.$models.user,
				customer: this.$models.user,

				userInfo: this.$models.user,

				availableProducts: {},

				productsQuotes: [],

				activeTemp: false,

				dialogs: {
					addProduct: false,
					modifyProduct: false
				},

				activeProduct: this.$models.product,
				temporaryProduct: this.$models.product,

				product: '',
				pDescription: '',
				quantity: '',
				value: '',

				pro: false,

				date: null,
				menu: false,

				cdialog: false,

				dDate: '',
				avatar: 'https://images-na.ssl-images-amazon.com/images/I/61Jvm0rckSL._SL1030_.jpg',

				title: '',
				content: '',

				Devis: [],

				quoteElHeaders: this.$tableHeaders.quoteElements
			}
		},

		computed: {
			total: function () {
				return Math.round(Number(this.value) * Number(this.quantity))
			},
			formattedDate() {
				return this.dDate ? this.$moment(this.dDate).format('DD/MM/YYYY') : ''
			}
		},

		watch: {
			menu(val) {
				val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
			},
		},

		methods: {
			sumField() {
				// sum data in give key (property)
				//return this.productsQuotes.reduce((a, b) => a + (b.value || 0), 0)

				var total = 0;

				this.productsQuotes.forEach((product) => {
					total += Number(product.value) * Number(product.quantity);
				});

				return total;
			},
			save(date) {
				this.$refs.menu.save(date);
			},
			submit() {
				const devis = {
					'dueDate': new Date(this.dDate),
					'discount': {
						'value': Number(this.quote.discount.value),
						'label': this.quote.discount.label
					},
					'wage': Number(this.quote.wage),
					'value': Number((this.sumField() - this.quote.discount.value) + (this.sumField() * (this.quote.wage/100) )),

					'remark': this.quote.remark
				};

				this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).set(devis, {
					merge: true
				});

				this.$store.dispatch('setSnackbar', {
					text: `Le devis n°${this.quote.nQuote} a été modifié !`,
				});
			},

			addProductToQuote(product, temporary = false){
				var productToAdd = {};

				if(temporary){
					productToAdd = {
						...this.temporaryProduct,
						value: Number(this.temporaryProduct.value),
						quantity: Number(this.temporaryProduct.quantity)
					}
				}
				else {
					productToAdd = {
						...product,
						value: Number(product.value),
						quantity: 1
					}
				}

				this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).collection("products").add({
					...productToAdd
				}).then(() => {
					this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).set({
						value: Number((this.sumField() - this.quote.discount.value) + (this.sumField() * (this.quote.wage/100) ))
					}, {
						merge: true
					});

					this.$store.dispatch('setSnackbar', {
						text: `Le produit ${product.name} à été ajouté au devis !`
					});
				});

				this.dialogs.addProduct = false
			},

			removeProductToQuote(product){
				this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).collection("products"), `Le produit ${product.name} à été supprimé du devis !`);

				this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).set({
					value: Number((this.sumField() - this.quote.discount.value) + (this.sumField() * (this.quote.wage/100) ))
				}, {
					merge: true
				});
			},

			modifyProduct(){
				this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).collection("products").doc(this.activeProduct.id).set({
					...this.activeProduct,
					value: Number(this.activeProduct.value),
					quantity: Number(this.activeProduct.quantity)
				}, {
					merge: true
				}).then(() => {
					this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).set({
						value: Number((this.sumField() - this.quote.discount.value) + (this.sumField() * (this.quote.wage/100) ))
					}, {
						merge: true
					});

					this.$store.dispatch('setSnackbar', {
						text: `Le produit ${this.activeProduct.name} à été modifié pour ce devis !`
					});
				});
			},

			statusQuote(status){
				this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).set({
					status: status
				}, {
					merge: true
				});

				if(status === 1) this.submit();

				if(status != 0) this.quoteElHeaders = this.quoteElHeaders.slice(0, 5);

				this.$store.dispatch('setSnackbar', {
					text: `Le devis à été modifié !`
				});
			}
		},

		created() {
			document.title = `Affichage devis - Invité`;

			if(this.$firebase.auth().currentUser){
				this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
					this.userInfo = doc.data();

					this.$db.collection("companies").doc(this.userInfo.company).collection("products").onSnapshot((res) => {
						this.availableProducts = [];

						res.forEach((document) => {
							this.availableProducts.push({
								...document.data(),
								id: document.id,
							});
						});
					});

					this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).onSnapshot(quoteDoc => {
						this.quote = quoteDoc.data();

						if(this.quote.status === 0) {
							this.quoteElHeaders = this.quoteElHeaders.slice(0, 5);

							this.quoteElHeaders.push({
								text: 'Actions',
								align: 'center',
								value: 'actions',
								sortable: false,
							});
						}

						this.dDate = this.$moment(this.quote.dueDate.toDate() || new Date()).format("YYYY-MM-DD");
						
						this.$db.collection("companies").doc(this.userInfo.company).collection("customers").doc(this.quote.customer).onSnapshot(customerDoc => {
							this.customer = {
								...this.$models.user,
								...customerDoc.data()
							};

							if (!customerDoc.data()) {
								this.$db.collection("companies").doc(this.userInfo.company).collection("companies").doc(this.quote.customer).onSnapshot(companyDoc => {
									this.customer = {
										...this.$models.company,
										...companyDoc.data()
									};

									this.pro = true;

									document.title = `Devis n°${this.quote.nQuote} - ${this.customer.name}`;
								});
							}
							else {
								document.title = `Devis n°${this.quote.nQuote} - ${this.customer.lastName.toUpperCase()} ${this.customer.firstName}`;
							}
						});

						this.$db.collection("companies").doc(this.userInfo.company).onSnapshot(ownerDoc => {
							this.owner = ownerDoc.data();
						});

						this.$db.collection("companies").doc(this.userInfo.company).collection("quotes").doc(this.id).collection("products").onSnapshot((res) => {
							this.productsQuotes = [];

							res.forEach((document) => {
								this.productsQuotes.push({
									...document.data(),
									id: document.id,
								});
							});
						});
					});
				});
			}
			else {
				this.$db.collection("companies").onSnapshot((res) => {
					res.forEach((document) => {
						this.$db.collection("companies").doc(document.id).collection("quotes").doc(this.id).onSnapshot(quoteDoc => {
							if(!quoteDoc.data()) return;
							this.quote = quoteDoc.data();

							this.dDate = this.$moment(this.quote.dueDate.toDate() || new Date()).format("YYYY-MM-DD");

							this.$db.collection("companies").doc(document.id).collection("customers").doc(this.quote.customer).onSnapshot(customerDoc => {
								this.customer = {
									...this.$models.user,
									...customerDoc.data()
								};

								if (!customerDoc.data()) {
									this.$db.collection("companies").doc(document.id).collection("companies").doc(this.quote.customer).onSnapshot(companyDoc => {
										this.customer = {
											...this.$models.company,
											...companyDoc.data()
										};

										this.pro = true;

										document.title = `Devis n°${this.quote.nQuote} - ${this.customer.name}`;
									});
								}
								else {
									document.title = `Devis n°${this.quote.nQuote} - ${this.customer.lastName.toUpperCase()} ${this.customer.firstName}`;
								}
							});

							this.$db.collection("companies").doc(document.id).onSnapshot(ownerDoc => {
								this.owner = ownerDoc.data();
							});

							this.$db.collection("companies").doc(document.id).collection("quotes").doc(this.id).collection("products").onSnapshot((res) => {
								this.productsQuotes = [];

								res.forEach((document) => {
									this.productsQuotes.push({
										...document.data(),
										id: document.id,
									});
								});
							});
						});
					});
				});

				
			}
			
		},
	}
</script>